/**
 * WooCommerce modal AlpineJS x-data
 */
function woocommerce() {
  return {
    clientKey: "",
    clientSecret: "",
    shopURL: "",
    buttonTxt: window.gettext("Add"),
    url: "/integrations/woocommerce/authenticate",
    get payload() {
      return {
        api_client_id: this.clientKey,
        api_secret_key: this.clientSecret,
        url: this.shopURL,
      };
    },
    showToast: true,
  };
}

export default woocommerce;
