/**
 * Prestashop modal AlpineJS x-data
 */
const prestashop = () => ({
  apikey: "",
  shopURL: "",
  buttonTxt: window.gettext("Add"),
  url: "/integrations/prestashop/authenticate",
  get payload() {
    return {
      apikey: this.apikey,
      url: this.shopURL,
    };
  },
  showToast: true,
});
export default prestashop;
