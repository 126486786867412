/**
 * Magento modal AlpineJS x-data
 */
function magento() {
  return {
    apikey: "",
    secretkey: "",
    shopURL: "",
    isV2: true,
    buttonTxt: window.gettext("Add"),
    url: "/integrations/magento/authenticate",
    get payload() {
      return {
        apikey: this.apikey,
        secretkey: this.secretkey,
        url: this.shopURL,
        isV2: this.isV2,
      };
    },
    get versionLabel() {
      return `Magento v${this.isV2 ? 2 : 1}`;
    },
    async callback(response) {
      const { url } = await response.json();
      if (url) {
        window.location = url;
      }
    },
    showToast: true,
  };
}

export default magento;
