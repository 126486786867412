import dialogPolyfill, {
  needsDialogPolyfill,
  shouldUpgrade,
} from "./dialogPolyfill";

export default function (Alpine) {
  const availableModals = new Map();
  const modals = Alpine.reactive({
    active: null,
  });

  /** EventTarget for modal event management */
  const modalEvents = new EventTarget();
  const dispatchEvent = (type, detail) =>
    modalEvents.dispatchEvent(new CustomEvent(type, { detail }));

  /**
   * $modal returns a method object for working with the currently active modal.
   * '.open()' opens the modal with the provided id,
   * '.close()' closes the active modal,
   * and '.set()' modifies the current modals options.
   * Implements EventTarget
   */

  const $modal = {
    get isActive() {
      return !!modals.active;
    },
    get id() {
      return modals.active?.el.id;
    },
    open: (id, options) => {
      if (id === modals.active?.el.id) return;
      if (modals.active) {
        modals.active.closing = true;
      }
      if (!availableModals.has(id)) {
        if (Alpine.store("modal")?.isRegistered(id)) {
          return Alpine.store("modal").setModal(id);
        }
        return logError(`Modal with id "${id}" does not exist`);
      }
      modals.active = availableModals.get(id);
      if (options) Object.assign(modals.active, options);
      modals.active.el.showModal();
      dispatchEvent("open-modal", { id });
      modals.active.el.dispatchEvent(
        new CustomEvent("open-modal", { bubbles: true, details: { id } }),
      );
    },
    close: () => {
      if (!modals.active) return;
      modals.active.closing = true;
      dispatchEvent("close-modal", { id: modals.active.id });
      modals.active.el.dispatchEvent(
        new CustomEvent("close-modal", {
          bubbles: true,
          details: { id: modals.active.id },
        }),
      );
    },
    set: (options) => {
      if (modals.active) {
        Object.assign(modals.active, options);
      }
    },
    get details() {
      return modals.active?.details;
    },
    addEventListener: modalEvents.addEventListener.bind(modalEvents),
    removeEventListener: modalEvents.removeEventListener.bind(modalEvents),
  };

  /**
   * x-modal instantiates a modal instance, adding it to available modals. The
   * id is taken from the element in question, and default options are taken
   * from the passed expression. This binds listeners and other controls.
   */
  Alpine.directive("modal", (el, { expression }, { evaluate, cleanup }) => {
    if (shouldUpgrade(el)) {
      return upgradeDialog(el);
    }

    const id = el.id;
    if (!id) {
      return logError("Modal must have an id", el);
    }

    if (availableModals.has(id)) {
      return logError(`Modal with id "${id}" already exists`, el);
    }

    const { backdrop = true, keyboard = true } = expression
      ? evaluate(expression)
      : {};
    const modal = Alpine.reactive({
      el,
      closing: false,
      backdrop,
      keyboard,
      details: {},
    });

    availableModals.set(id, modal);

    cleanup(() => {
      availableModals.delete(id);
      if (modals.active === modal) modals.active = null;
    });

    Alpine.bind(el, {
      "@animationend"({ animationName }) {
        if (animationName !== "dialog-out") return;
        modal.closing = false;
        el.close();
        if (modals.active === modal) modals.active = null;
      },
      "@click.self"() {
        if (modal.backdrop) $modal.close();
      },
      "@cancel.prevent"() {
        if (modal.keyboard) $modal.close();
      },
      ":class"() {
        return {
          "dialog-out backdrop:animate-fade-out": modal.closing,
          "dialog-in backdrop:animate-fade-in": !modal.closing,
        };
      },
    });
  });

  Alpine.magic("modal", () => $modal);
  Alpine.$modal = $modal;

  const contentAttributes = ["class", "style", "data-size"];
  const upgradeDialog = (el) => {
    const dialog = document.createElement("dialog-polyfill");
    const contents = document.createElement("div");
    Array.prototype.forEach.call(el.attributes, (attr) =>
      (contentAttributes.includes(attr.name) ? contents : dialog).setAttribute(
        attr.name,
        attr.value,
      ),
    );

    // This dismantles the existing Alpine tree, replaces the element, and then initializes the new one
    const alpineKeys = Object.fromEntries(
      Object.entries(el).filter(([k]) => k.startsWith("_x_")),
    );
    Alpine.destroyTree(el);
    Alpine.mutateDom(() => {
      contents.replaceChildren(...el.childNodes);
      dialog.append(contents);
      el.replaceWith(dialog);

      // manually update the dialog element with the polyfill
      customElements.upgrade(dialog);
    });
    /* Access some Alpine internals to clone the state for teleported dialogs */
    Object.assign(dialog, alpineKeys);
    if (dialog._x_dataStack) {
      dialog.setAttribute("data-has-alpine-state", "true");
    }
    if (dialog._x_teleportBack) {
      dialog._x_teleportBack._x_teleport = dialog;
    }
    Alpine.initTree(dialog);
  };
}

const logError = (message, el) => {
  return window.$silvr?.utils?.captureException?.(
    new Error(message, {
      cause: el,
    }),
  );
};

if (needsDialogPolyfill()) dialogPolyfill();
