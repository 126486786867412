/**
 * Amazon ads modal AlpineJS x-data
 */
function amazonads() {
  return {
    regionCode: "",
    buttonTxt: window.gettext("Add"),
    selectedOption: "",
    url: "/integrations/amazonads/authenticate",
    get payload() {
      return {
        region_code: this.regionCode,
      };
    },
    options: [
      { value: "NA", text: window.gettext("North America") },
      { value: "EU", text: window.gettext("Europe") },
      { value: "FE", text: window.gettext("Far East") },
    ],
    async callback(response) {
      const { redirect_to } = await response.json();
      if (redirect_to) window.location.replace(redirect_to);
    },
    showToast: false,
  };
}

export default amazonads;
