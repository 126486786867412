import amazon from "./amazon";
import amazonads from "./amazonads";
import chargebee from "./chargebee";
import magento from "./magento";
import prestashop from "./prestashop";
import shopify from "./shopify";
import woocommerce from "./woocommerce";
import shopware from "./shopware";

export default {
  amazon,
  amazonads,
  chargebee,
  magento,
  prestashop,
  shopify,
  woocommerce,
  shopware,
};
