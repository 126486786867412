/**
 * Shopware modal AlpineJS x-data
 */
function shopware() {
  return {
    shopURL: "",
    apiKey: "",
    apiSecret: "",
    userName: "",
    isV6: true,
    buttonTxt: window.gettext("Add"),
    url: "/integrations/shopware/authenticate",
    get payload() {
      return {
        url: this.shopURL,
        apiKey: this.apiKey,
        apiSecret: this.apiSecret,
        userName: this.userName,
        isV6: this.isV6,
      };
    },
    get versionLabel() {
      return `Shopware v${this.isV6 ? 6 : 5}`;
    },
    showToast: true,
  };
}

export default shopware;
