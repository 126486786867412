/**
 * Chargebee modal AlpineJS x-data
 */
function chargebee() {
  return {
    apikey: "",
    subdomain: "",
    buttonTxt: window.gettext("Add"),
    url: "/integrations/chargebee/authenticate",
    get payload() {
      return {
        apikey: this.apikey,
        subdomain: this.subdomain,
      };
    },
    showToast: true,
  };
}

export default chargebee;
